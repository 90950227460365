import {
  Checkbox,
  Layout,
  Radio,
  Typography,
} from "@visma-netvisor/react-component-library";
import { CheckboxInput } from '@visma-netvisor/react-component-library/lib/components/checkbox/CheckboxInput'
import { InlineLabel } from '@visma-netvisor/react-component-library/lib/components/componentBase/InlineLabel'
import { Trans, useTranslation } from "react-i18next";
import { isNewCompany } from "../../Domain/NewCampaign";
import styled from "styled-components";

type AccountingCampaignProps = {
  accountingServiceNeed: boolean | null;
  setAccountingServiceNeed: (value: boolean) => void;
}

type MultipleServicesProps = AccountingCampaignProps & {
    holviServiceNeed: boolean | null;
    setHolviServiceNeed: (value: boolean) => void;
};

type Props = MultipleServicesProps & {
  tradeRegistrationYear: number;
}

const MultipleServices = ({
  accountingServiceNeed,
  setAccountingServiceNeed,
  holviServiceNeed,
  setHolviServiceNeed
}: MultipleServicesProps) => {
  const { t } = useTranslation();

  const clearBoth = () => {
    setAccountingServiceNeed(false);
    setHolviServiceNeed(false);
  }

  const accountingServiceNeedLabel = <Trans i18nKey="OnlineStatus.PossiblyYesShareContactToAccounting" components={[<b></b>]}></Trans>
  const holviServiceNeedLabel = <Trans i18nKey="OnlineStatus.PossiblyYesShareContactToHolvi" components={[<b></b>]}></Trans>

  return (
    <Layout.Flex flexDirection="column" flexGap={0}>
      <StyledTypography
        as="h3"
        color="secondary"
        variant="headingH4Regular"
        styleOverrides={{ paddingTop: 20, paddingBottom: 10 }}
      >
        {t("OnlineStatus.NeedMultipleServices")}
      </StyledTypography>
      <Layout.Flex 
        alignItems="center"
        flexGap={8}
        inline
        styleOverrides={{ minHeight: 32 }}
      >
        <CheckboxInput
          aria-checked={accountingServiceNeed === true}
          id="checkbox_accountingServiceNeed"
          name="accountingServiceNeed"
          checked={accountingServiceNeed === true}
          onChange={(e) => setAccountingServiceNeed(e.target.checked)}
          type="checkbox"
        />
        <InlineLabel htmlFor="checkbox_accountingServiceNeed" variant="horizontal">
          {accountingServiceNeedLabel}
        </InlineLabel>
      </Layout.Flex>
      <Layout.Flex
        alignItems="center"
        flexGap={8}
        inline
        styleOverrides={{ minHeight: 32 }}
      >
        <CheckboxInput
          aria-checked={false}
          type="checkbox"
          id="checkbox_holviServiceNeed"
          name="holviServiceNeed"
          checked={holviServiceNeed === true}
          onChange={(e) => setHolviServiceNeed(e.target.checked)}
        />
        <InlineLabel htmlFor="checkbox_holviServiceNeed" variant="horizontal">
          {holviServiceNeedLabel}
        </InlineLabel>
      </Layout.Flex>
      <Checkbox
        label={t("OnlineStatus.NotThisTimeThanks")}
        name="accountingServiceNeed"
        checked={accountingServiceNeed === false && holviServiceNeed === false}
        onChange={clearBoth}
      />
    </Layout.Flex>
  )
}

const AccountingCampaign = ({
  accountingServiceNeed,
  setAccountingServiceNeed
}: AccountingCampaignProps) => {
  const { t } = useTranslation();

  return (
    <Layout.Flex flexDirection="column" flexGap={0}>
      <StyledTypography
        as="h3"
        color="secondary"
        variant="headingH4Regular"
        styleOverrides={{ paddingTop: 20, paddingBottom: 10 }}
      >
        {t("OnlineStatus.NeedAccountingServices")}
      </StyledTypography>
      <Radio
        label={t("OnlineStatus.PossiblyYesShareContact")}
        name="accountingServiceNeed"
        checked={accountingServiceNeed === true}
        onChange={() => setAccountingServiceNeed(true)}
      />
      <Radio
        label={t("OnlineStatus.NotThisTimeThanks")}
        name="accountingServiceNeed"
        checked={accountingServiceNeed === false}
        onChange={() => setAccountingServiceNeed(false)}
      />
    </Layout.Flex>
  )
}

const AccountingServiceNeedForm = ({
  tradeRegistrationYear,
  accountingServiceNeed,
  setAccountingServiceNeed,
  holviServiceNeed,
  setHolviServiceNeed,
}: Props) => {
  const { t } = useTranslation();
  const isThisANewCompany = isNewCompany(tradeRegistrationYear);

  return (
    <>
      <StyledTypography
        as="h3"
        color="secondary"
        variant="headingH3Semibold"
        styleOverrides={{ textAlign: "center" }}
      >
        {t("OnlineStatus.OneMoreQuestion")}
      </StyledTypography>
      { isThisANewCompany 
        ? <MultipleServices 
            accountingServiceNeed={accountingServiceNeed}
            setAccountingServiceNeed={setAccountingServiceNeed}
            holviServiceNeed={holviServiceNeed}
            setHolviServiceNeed={setHolviServiceNeed}
          /> 
        : <AccountingCampaign 
            accountingServiceNeed={accountingServiceNeed}
            setAccountingServiceNeed={setAccountingServiceNeed}
          />
      }
    </>
  );
};

export default AccountingServiceNeedForm;

const StyledTypography = styled(Typography)`
  @media (max-width: 768px) {
    font-size: 20px;
    padding-bottom: 80px;
  }
`;
