import { gql, useMutation } from "@apollo/client";
import { ArrowExitIcon } from "@visma-netvisor/nv-react-icons";
import {
  Button,
  Layout,
  Link,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { sendTag } from "../../analytics";
import { NETVISOR_LOGIN_URL, strings } from "../../constants";
import { cleanBrowserCache } from "../../utils/cookieUtil";
import { redirectToNetvisor } from "../../utils/redirectToNetvisor";
import { logFrontendError } from "../LogFrontendError";
import { useNetvisorLogin } from "../hooks/useNetvisorLogin";
import AccountingServiceNeedForm from "./AccountingServiceNeedForm";
import {
  UpdateOpportunity,
  UpdateOpportunityVariables,
} from "./__generated__/UpdateOpportunity";

const UPDATE_OPPORTUNITY_MUTATION = gql`
  mutation UpdateOpportunity($id: String!, $accountingServiceNeed: Boolean!, $holviServiceNeed: Boolean!) {
    updateOpportunity(
      input: { 
        id: $id, 
        accountingServiceNeed: $accountingServiceNeed, 
        holviServiceNeed: $holviServiceNeed 
      }
    ) {
      id
    }
  }
`;

const CompanyCreationSuccess = ({ tradeRegistrationYear }: { tradeRegistrationYear: number }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const netvisorLoginMutation = useNetvisorLogin();
  const [accountingServiceNeed, setAccountingServiceNeed] = useState<boolean | null>(null);
  const [holviServiceNeed, setHolviServiceNeed] = useState<boolean | null>(null);
  const [updateOpportunity] = useMutation<
    UpdateOpportunity,
    UpdateOpportunityVariables
  >(UPDATE_OPPORTUNITY_MUTATION);

  const leadId = sessionStorage.getItem("leadId");

  sendTag({ status: "companyCreationSuccess" });

  const updateAccountingServiceNeed = async (
    accountingServiceNeed: boolean,
    holviServiceNeed: boolean
  ) => {
    try {
      await updateOpportunity({
        variables: {
          id: leadId?.toString() || "",
          accountingServiceNeed: accountingServiceNeed,
          holviServiceNeed: holviServiceNeed
        },
      });
    } catch (error) {
      await logFrontendError({
        message: "Error updating accountingServiceNeed for opportunity:",
        details: { leadId, accountingServiceNeed },
        error: error.toString(),
      });
    }
  };

  const login = async () => {
    sendTag({ status: "redictedToNetvisor" });

    try {
      const token = await netvisorLoginMutation.mutateAsync();

      if (token) {
        sessionStorage.removeItem(strings.ORDER_ID_COOKIE_NAME);
        redirectToNetvisor(NETVISOR_LOGIN_URL, token);
      }
    } catch (error) {
      await logFrontendError({
        info: "Netvisor redirect failed",
        error,
      });
      setLoginError(true);
    }
  };

  if (loginError) {
    cleanBrowserCache();
  }

  const handleButtonClick = async () => {
    setIsLoading(true);
    await updateAccountingServiceNeed(accountingServiceNeed ?? false, holviServiceNeed ?? false);
    await login();
  };

  return (
    <>
      <Layout.Flex alignItems="center" flexDirection="column">
        <StyledTypography
          as="h1"
          color="secondary"
          variant="headingH1Semibold"
          styleOverrides={{ paddingTop: 60 }}
        >
          {t("OnlineStatus.CompanySuccessfullyCreated")}
        </StyledTypography>

        <AccountingServiceNeedForm
          tradeRegistrationYear={tradeRegistrationYear}
          accountingServiceNeed={accountingServiceNeed}
          setAccountingServiceNeed={setAccountingServiceNeed}
          holviServiceNeed={holviServiceNeed}
          setHolviServiceNeed={setHolviServiceNeed}
        />

        {loginError && (
          <StyledTypography
            as="h4"
            color="error"
            variant="contentHeadingH4Semibold"
            styleOverrides={{ paddingBottom: 130 }}
          >
            {t("OnlineStatus.ErrorTransitioningBankAccountSettings")}{" "}
            <Link href="https://login.netvisor.fi">
              <ArrowExitIcon />
            </Link>
          </StyledTypography>
        )}
        <Button isLoading={isLoading} onClick={handleButtonClick}>
          {t("OnlineStatus.GoToBankAccountSettings")}
        </Button>
      </Layout.Flex>
    </>
  );
};

export default CompanyCreationSuccess;

const StyledTypography = styled(Typography)`
  @media (max-width: 768px) {
    font-size: 20px;
    padding-bottom: 80px;
  }
`;
