import { FormState } from "react-hook-form";
import { FormInputs } from "../Components/Content";
import { getUrlParam } from "../utils";

export function getTradeRegistrationYear() {
  const tradeRegisterDate = sessionStorage.getItem("tradeRegisterDate");

  if (!tradeRegisterDate) {
    return null;
  }

  const parsedTradeRegisterDate = new Date(tradeRegisterDate);
  return parsedTradeRegisterDate.getFullYear();
}

export function isNewCompany(tradeRegistrationYear?: number | undefined) {
  const registrationYear = tradeRegistrationYear ?? getTradeRegistrationYear()
  
  if (registrationYear === 2025) {
    return true;
  }
  else if (registrationYear === 2024) {
    const currentDate = new Date();
    if (currentDate < new Date(2025, 1, 28, 23, 59, 59)) {
      return true;
    }
  }

  return false;
}

export function isNewCampaignActivated() {
  return isNewCompany() && getUrlParam("packet") === "professional";
}

export function activateNewCompanyCampaign(
  formValues: FormInputs,
  formState: FormState<FormInputs>
) {
  changePacket(formValues, formState, "professional");
}

function changePacket(
  formValues: FormInputs,
  formState: FormState<FormInputs>,
  packet: string
) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set("packet", packet);

  for (const [key, value] of Object.entries(formValues)) {
    if (typeof value !== "string") {
      continue;
    }

    if (Object.keys(formState.errors).includes(key)) {
      continue;
    }

    if (value === undefined || value === "") {
      continue;
    }

    sessionStorage.setItem(key, value as string);
  }

  window.location.href = `${
    window.location.pathname
  }?${searchParams.toString()}`;
}
